<template>
  <div>
    <vs-card>
      <div class="flex flex-wrap m-5">
        <div v-if="toggleScreenSize" class="w-full">
          <div
            :class="complianceDetails.status.toLowerCase() + ' rounded-full text-sm text-center px-3 py-1 inline-block font-semibold'">
            {{ getStatusData(complianceDetails.status) }}
          </div>
        </div>
        <div v-if="toggleScreenSize" class="w-full mt-5">
          <p class="text-base ">
            {{ `${complianceQuarter} ${complianceYear}` }}
          </p>
          <p class="hidden text-3xl font-bold lg:block">{{ complianceDetails.clinicName }}</p>
          <p class="text-2xl font-bold lg:hidden">{{ complianceDetails.clinicName }}</p>
          <div class="flex flex-wrap mt-2" v-if="complianceDetails.bookingDate">
            <div class="w-full mb-5 lg:mr-5 lg:w-auto">
              <p class="text-base " v-if="complianceDetails.bookingDate">Booking confirmed</p>
              <p class="text-base " v-else>No Booking Date</p>
              <p class="text-base " v-if="complianceDetails.bookingDate">
                {{ complianceDetails.bookingDate }}
              </p>
            </div>
            <div class="mb-5 mr-5" v-if="status === 'In Review'">
              <vs-button color="success" type="border" @click="gotoBookingLink">Booking Link<Link:touch></Link:touch>
              </vs-button>
            </div>
            <div class="mb-5 mr-5">
              <vs-button color="danger" type="border" @click="openConfirm">Cancel Booking</vs-button>
            </div>
          </div>
          <p>Files supported for upload: PNG, JPG, JPEG, HEIC, PDF, MOV, MP4 </p>
          <vs-divider class="mt-0" />
        </div>
        <vs-popup class="overflow-hidden" title="Image Preview" :active.sync="popupImageActivo">
          <div class="w-full">
            <div class="w-full">
              <img class="w-full object-fit" :key="imagePreviewSrc.src" :src="imagePreviewSrc.src" />
            </div>
            <div class="flex justify-end w-full" v-if="status === 'In Review'">
              <vs-button v-if="complianceDetails.status === 'failed'" disabled size="lg" color="danger"
                class="mt-5 text-right" @click="removePreview">Remove</vs-button>
              <vs-button v-else size="lg" color="danger" class="mt-5 text-right"
                @click="removePreview">Remove</vs-button>
            </div>
          </div>
        </vs-popup>
        <div class="w-full">
          <div class="flex flex-no-wrap w-full mt-5"></div>
          <div v-if="isStocktake && complianceDrugbook.length > 0" class="flex flex-no-wrap w-full mt-5">
            <div class="w-full">

              <div v-if="toggleScreenSize" class="flex justify-between w-full">
                <div>
                  <p class="text-xl font-bold md:text-2xl md:font-bold">DrugBook</p>
                  <p>
                    Please complete a stocktake to confirm your drugbook
                    balances.
                  </p>
                </div>
                <div v-if="!completeComplianceCheck" class="flex items-center lg:p-5 lg:flex">
                  <vs-button @click="onStocktake()" color="success" type="border" v-if="!completeComplianceCheck">
                    Stocktake
                  </vs-button>
                </div>
              </div>

              <div v-if="errors.includes('drug')"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
                <span class="span-text-validation">Please complete a stocktake to confirm your drugbook
                  balances.</span>
              </div>
              <div class="md:mt-4">
                 <!-- for stocktake feature -->
                <vs-table v-if="completeComplianceCheck" class="hidden md:block" ref="table" :sst="true"
                  :total="totalDocs" :data="complianceDrugbook">
                  <template slot="thead">
                    <vs-th style="background: #bcd6ce" sort-key="clinicName">PRODUCT NAME</vs-th>
                    <vs-th style="background: #bcd6ce" sort-key="orderYear">DRUGBOOK BALANCE</vs-th>
                    <vs-th style="background: #bcd6ce" sort-key="orderMonth">COUNTED QUANTITY</vs-th>
                    <vs-th style="background: #bcd6ce" sort-key="status">VARIANCE</vs-th>
                    <vs-th style="background: #bcd6ce" sort-key="status">NOTES</vs-th>
                    <vs-th style="background: #bcd6ce">ACTIONS</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="data[indextr].productName">{{
                          data[indextr].productName
                      }}</vs-td>
                      <vs-td :data="data[indextr].drugBookBalance">{{
                          data[indextr].drugBookBalance
                      }}</vs-td>
                      <vs-td :data="data[indextr].countedQuality">{{
                          data[indextr].countedQuality
                      }}</vs-td>
                      <vs-td :data="data[indextr].variance" style="color: #e02020">
                        <p v-if="data[indextr].variance == 0">
                          <!-- {{data[indextr].variance}} -->
                          {{ `${data[indextr].variance}${data[indextr].unit === "ml" ? data[indextr].unit : "u"}` }}
                        </p>
                        <p v-else>
                          {{
                              `${data[indextr].drugBookBalance > data[indextr].countedQuality
                                ? `-${data[indextr].variance}`
                                : `+${Math.abs(data[indextr].variance)}`
                              }${data[indextr].unit === "ml" ? data[indextr].unit : "u"}`
                          }}
                        </p>
                      </vs-td>
                      <vs-td :data="data[indextr].notes">{{
                          data[indextr].notes
                      }}</vs-td>
                      <vs-td :data="data[indextr].productName">
                        <vs-button @click="toggleModal(data[indextr])" type="border">Edit</vs-button>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
                <div v-if="(toggleScreenSize)" class="md:hidden">
                  <div class="p-2 my-2 rounded-lg shadow-md" v-for="drugData in complianceDrugbook" :key="drugData._id">
                    <div class="flex w-full justify-items-center">
                      <div style="color: #0e4333" class="w-1/2 py-1 text-sm font-semibold leading-loose">
                        {{ drugData.productName }}
                      </div>
                      <div @click="toggleModal(drugData)" style="color: #0e4333"
                        class="w-1/2 my-1 text-sm font-semibold leading-loose text-right cursor-auto">
                        <vs-icon class="cursor-auto" icon="edit" />
                      </div>
                    </div>
                    <div class="flex w-full justify-items-center">
                      <div style="color: #6e6b7b" class="w-1/2 my-1 text-xs font-medium">
                        Counted Quantity
                      </div>
                      <div style="color: #6e6b7b" class="w-full my-1 text-xs font-medium text-right">
                        {{ `${drugData.countedQuality || "0"}${"u"}` }}
                      </div>
                    </div>
                    <div class="flex w-full justify-items-center">
                      <div style="color: #6e6b7b" class="w-1/2 my-1 text-xs font-medium">
                        Drugbook Balance
                      </div>
                      <div style="color: #6e6b7b" class="w-1/2 my-1 text-xs font-medium text-right">
                        {{ `${drugData.drugBookBalance || "0"}${"u"}` }}
                      </div>
                    </div>
                    <div class="flex w-full justify-items-center">
                      <div style="color: #e02020" class="w-1/2 my-1 text-xs font-medium">
                        Variance
                      </div>
                      <div style="color: #e02020" class="w-1/2 my-1 text-xs font-medium text-right">
                        <p v-if="drugData.variance == 0">
                          {{
                              `${`${Math.abs(drugData.variance)}`
                              }${drugData.unit === "ml" ? drugData.unit : "u"}`
                          }}
                        </p>
                        <p v-else>
                          {{
                              `${drugData.drugBookBalance > drugData.countedQuality
                                ? `-${drugData.variance}`
                                : `+${Math.abs(drugData.variance)}`
                              }${drugData.unit === "ml" ? drugData.unit : "u"}`
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="flex w-full justify-items-center">
                      <div style="color: #6e6b7b" class="w-1/2 my-1 text-xs font-medium">
                        Notes
                      </div>
                      <div style="color: #6e6b7b" class="w-1/2 my-1 text-xs font-medium text-right">
                        {{ drugData.notes }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end for stocktake feature -->

                <div v-if="(isMobileView && popupActive)" class=" form-container">
                  <editStocktakeForm :selectedStockTake="selectedStockTake" @onClose="handleCancel"
                    @fetchStocktake="getClinicDrugbookProducts" :popupActive="popupActive" />
                </div>
              </div>

              <div v-if="errors.includes('drug')"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
                <span class="span-text-validation">Please complete a stocktake to confirm your drugbook
                  balances.</span>
              </div>
            </div>
          </div>
          <div v-else class="flex flex-no-wrap w-full mt-5">
            <div class="w-full">
              <div class="flex justify-between w-full">
                <div>
                  <p class="text-xl font-bold md:text-2xl md:font-bold">DrugBook</p>
                  <p v-if="!isDigitalDrubook">Please upload photos of your drugbook from last month</p>
                  <p v-else>A PDF export of your Drugbook records for last 30 days has been generated. Please review and make necessary changes to your Drugbook before submitting this compliance check.</p>
                  <progress-bar class="mt-5" v-if="isDigitalDrubook" :month="monthCompliance" :year="yearCompliance" :clinicId="complianceDetails.clinicId"/>
                </div>
                <div class="flex items-center lg:flex">
                  <div class="overflow-visible vs-con-loading__container"
                    style="text-align: center; position: relative">
                    <label
                        v-if="!isDigitalDrubook"
                        class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                        style="
                          border: 1px solid #cccccc;
                          display: inline-block;
                          width: 100%;
                          border-radius: 5px;
                          padding: 10px;
                          border: 1px solid rgba(var(--vs-primary),1);
                          color: rgba(var(--vs-primary),1);
                        "
                      >
                      <span>
                        <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                        <span class="vs-button-text vs-button--text"> Upload </span>

                        <input type="file" :disabled="complianceDetails.status==='completed'" accept="image/*,application/pdf,.heic" @change="uploadImage($event, 'drug')"
                        id="drugs-file-input" hidden multiple />
                          <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                      </span>
                    </label>
                  </div>
                </div>

              </div>

              <div v-if="errors.includes('drug')"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
                <span class="span-text-validation">Please upload photos of your drugbook from last month.</span>
              </div>
              <div class="flex w-full flex-wra">
                <div :key="image.photoLink" class="w-32 m-2" v-for="(image, index) in images.drug">
                  <div @click="ImagePreview(image.photoLink, 'drug')" v-if="image.photoLink.indexOf('pdf') < 0"
                    :key="index">
                    <vs-image class="object-cover w-full hover:shadow-lg hover-scale" :src="image.photoLink" />
                  </div>
                  <template
                      v-if="image.photoLink.indexOf('pdf') > -1"
                    >
                      <div
                      v-if="!isDigitalDrubook"
                        :key="index"
                        @click="DownloadPdf({ url: image.photoLink, name: 'drug' })"
                      >
                        <img class="object-cover w-full cursor-pointer hover:shadow-lg hover-scale" :key="image.index"
                        src="@/assets/images/icon-pdf.svg" />
                        <div class="text-center">
                          {{complianceDetails.clinicName}} <br/>
                          {{` [Drugbook] - ${complianceQuarter} ${complianceYear}`}}
                        </div>
                      </div>
                      <div
                        :key="index+'digital'"
                        v-else
                        @click="DownloadPdf({ url: image.photoLink, name: `${complianceDetails.clinicName} - Drugbook - Quarterly Compliance` })"
                      >
                        <img
                          class="w-full hover:shadow-lg hover-scale object-cover cursor-pointer"
                          :key="image.index"
                          src="@/assets/images/icon-pdf.svg"
                        />
                        {{`[${complianceDetails.clinicName}] [Drugbook] - [${complianceQuarter}] [${complianceYear}]`}}
                      </div>
                    </template>
                </div>
              </div>
              <div v-if="toggleScreenSize">
                <div>
                  <vs-list>
                    <vs-list-item :key="index" v-for="(note, index) in requestChanges.drug" icon="radio_button_checked"
                      :subtitle="`${note.requestNote} - ${formatDate(
                        note.createdAt
                      )}`">
                    </vs-list-item>
                  </vs-list>
                </div>
              </div>
            </div>
          </div>
          <vs-divider v-if="toggleScreenSize" />
          <div class="flex flex-no-wrap w-full mt-5" v-if="toggleScreenSize">
            <div class="w-full">
              <div class="hidden w-full lg:block">
                <p class="text-2xl font-bold ">Fridge Temperatures</p>
                <p>
                  Please upload photos of your fridge temperature monitoring book
                </p>
              </div>

              <div class="flex justify-between w-full lg:hidden">
                <div>
                  <p class="text-xl font-bold">Fridge Temperatures</p>
                  <p>
                    Please upload photos of your fridge temperature monitoring
                    book
                  </p>
                </div>
                <div class="flex items-center">
                  <div class="vs-con-loading__container" style="text-align: center; position: relative">
                    <label
                        class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                        style="
                          border: 1px solid #cccccc;
                          display: inline-block;
                          width: 100%;
                          border-radius: 5px;
                          padding: 10px;
                          border: 1px solid rgba(var(--vs-primary),1);
                          color: rgba(var(--vs-primary),1);
                        "
                      >
                      <span>
                        <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                        <span class="vs-button-text vs-button--text"> Upload </span>

                        <input type="file" accept="image/*,application/pdf,.heic" @change="uploadImage($event, 'fridge')"
                        id="fridge-file-input" hidden multiple />
                          <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="errors.includes('fridge')"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
                <span class="span-text-validation">Upload at least one(1) image</span>
              </div>
              <div class="flex flex-wrap w-full">
                <div :key="index" class="w-32 m-2" v-for="(image, index) in images.fridge">
                  <div @click="ImagePreview(image.photoLink, 'fridge')" v-if="image.photoLink.indexOf('pdf') < 0"
                    :key="index">
                    <vs-image class="object-cover w-full hover:shadow-lg hover-scale" :key="index"
                      :src="image.photoLink" />
                  </div>
                  <div :key="index" v-if="image.photoLink.indexOf('pdf') > -1"
                    @click="DownloadPdf({ url: image.photoLink, name: 'fridge' })">
                    <img class="object-cover w-full cursor-pointer hover:shadow-lg hover-scale" :key="image.index"
                      src="@/assets/images/icon-pdf.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div class="items-center hidden lg:flex">
              <div class="vs-con-loading__container" style="text-align: center; position: relative">
                <label
                    class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                      border: 1px solid rgba(var(--vs-primary),1);
                      color: rgba(var(--vs-primary),1);
                    "
                  >
                  <span>
                    <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                    <span class="vs-button-text vs-button--text"> Upload </span>

                    <input type="file" accept="image/*,application/pdf,.heic" @change="uploadImage($event, 'fridge')"
                      id="fridge-file-input" hidden multiple />
                      <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="toggleScreenSize">
            <div>
              <vs-list>
                <vs-list-item :key="index" v-for="(note, index) in requestChanges.fridge" icon="radio_button_checked"
                  :subtitle="`${note.requestNote} - ${formatDate(
                    note.createdAt
                  )}`">
                </vs-list-item>
              </vs-list>
            </div>
          </div>
          <vs-divider v-if="toggleScreenSize" />
          <div class="flex flex-no-wrap w-full mt-5" v-if="toggleScreenSize">
            <div class="w-full">
              <div class="hidden w-full lg:block">
                <p class="text-2xl font-bold ">Stock Photos</p>
                <p>
                  Please upload photos of your stock (fridge and cupboard stock)
                </p>
              </div>

              <div class="flex justify-between w-full lg:hidden">
                <div>
                  <p class="text-xl font-bold">Stock Photos</p>
                  <p>
                    Please upload photos of your stock (fridge and cupboard
                    stock)
                  </p>
                </div>
                <div class="flex items-center">
                  <div class="vs-con-loading__container" style="text-align: center; position: relative">
                    <label
                        class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                        style="
                          border: 1px solid #cccccc;
                          display: inline-block;
                          width: 100%;
                          border-radius: 5px;
                          padding: 10px;
                          border: 1px solid rgba(var(--vs-primary),1);
                          color: rgba(var(--vs-primary),1);
                        "
                      >
                      <span>
                        <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                        <span class="vs-button-text vs-button--text"> Upload </span>

                        <input type="file" accept="image/*,application/pdf,.heic" @change="uploadImage($event, 'stock')"
                            id="fridge-file-input" hidden multiple />
                          <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="errors.includes('stock')"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
                <span class="span-text-validation">Upload at least one(1) image</span>
              </div>
              <div class="flex flex-wrap w-full">
                <div :key="index" class="w-32 m-2" v-for="(image, index) in images.stock">
                  <div @click="ImagePreview(image.photoLink, 'stock')" v-if="image.photoLink.indexOf('pdf') < 0"
                    :key="index">
                    <vs-image class="object-cover w-full hover:shadow-lg hover-scale" :key="index"
                      :src="image.photoLink" />
                  </div>
                  <div :key="index" v-if="image.photoLink.indexOf('pdf') > -1"
                    @click="DownloadPdf({ url: image.photoLink, name: 'stock' })">
                    <img class="object-cover w-full cursor-pointer hover:shadow-lg hover-scale" :key="image.index"
                      src="@/assets/images/icon-pdf.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="items-center hidden lg:flex">
              <div class="vs-con-loading__container" style="text-align: center; position: relative">
                  <label
                    class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                      border: 1px solid rgba(var(--vs-primary),1);
                      color: rgba(var(--vs-primary),1);
                    "
                  >
                  <span>
                    <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                    <span class="vs-button-text vs-button--text"> Upload </span>

                    <input type="file" accept="image/*,application/pdf,.heic" @change="uploadImage($event, 'stock')"
                id="fridge-file-input" hidden multiple />
                      <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="toggleScreenSize">
            <div>
              <vs-list>
                <vs-list-item :key="index" v-for="(note, index) in requestChanges.stock" icon="radio_button_checked"
                  :subtitle="`${note.requestNote} -   ${formatDate(
                    note.createdAt
                  )}`">
                </vs-list-item>
              </vs-list>
            </div>
          </div>

          <vs-divider v-if="toggleScreenSize" />
          <div class="flex flex-no-wrap w-full mt-5" v-if="toggleScreenSize">
            <div class="w-full">
              <div class="hidden w-full lg:block">
                <p class="text-2xl font-bold ">Emergency Medication Photos</p>
                <p>
                  Please upload photos of your Hyalase and Adrenaline expiry dates
                </p>
              </div>

              <div class="flex justify-between w-full lg:hidden">
                <div>
                  <p class="text-xl font-bold">Emergency Medication Photos</p>
                  <p>
                    Please upload photos of your Hyalase and Adrenaline expiry dates
                  </p>
                </div>
                <div class="flex items-center">
                  <div class="vs-con-loading__container" style="text-align: center; position: relative">
                    <label
                        class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                        style="
                          border: 1px solid #cccccc;
                          display: inline-block;
                          width: 100%;
                          border-radius: 5px;
                          padding: 10px;
                          border: 1px solid rgba(var(--vs-primary),1);
                          color: rgba(var(--vs-primary),1);
                        "
                      >
                      <span>
                        <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                        <span class="vs-button-text vs-button--text"> Upload </span>

                        <input type="file" accept="image/*,application/pdf,.heic" @change="uploadImage($event, 'emergency')"
                            id="fridge-file-input" hidden multiple />
                          <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="errors.includes('emergency')"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
                <span class="span-text-validation">Upload at least one(1) image</span>
              </div>
              <div class="flex flex-wrap w-full">
                <div :key="index" class="w-32 m-2" v-for="(image, index) in images.emergency">
                  <div @click="ImagePreview(image.photoLink, 'emergency')" v-if="image.photoLink.indexOf('pdf') < 0"
                    :key="index">
                    <vs-image class="object-cover w-full hover:shadow-lg hover-scale" :key="index"
                      :src="image.photoLink" />
                  </div>
                  <div :key="index" v-if="image.photoLink.indexOf('pdf') > -1"
                    @click="DownloadPdf({ url: image.photoLink, name: 'emergency' })">
                    <img class="object-cover w-full cursor-pointer hover:shadow-lg hover-scale" :key="image.index"
                      src="@/assets/images/icon-pdf.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="items-center hidden lg:flex">
              <div class="vs-con-loading__container" style="text-align: center; position: relative">
                  <label
                    class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                      border: 1px solid rgba(var(--vs-primary),1);
                      color: rgba(var(--vs-primary),1);
                    "
                  >
                  <span>
                    <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                    <span class="vs-button-text vs-button--text"> Upload </span>

                    <input type="file" accept="image/*,application/pdf,.heic" @change="uploadImage($event, 'emergency')"
                id="fridge-file-input" hidden multiple />
                      <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="toggleScreenSize">
            <div>
              <vs-list>
                <vs-list-item :key="index" v-for="(note, index) in requestChanges.emergency" icon="radio_button_checked"
                  :subtitle="`${note.requestNote} -   ${formatDate(
                    note.createdAt
                  )}`">
                </vs-list-item>
              </vs-list>
            </div>
          </div>

          <vs-divider v-if="toggleScreenSize" />
        </div>
        <div class="w-full" v-if="toggleScreenSize">
          <div class="flex flex-no-wrap w-full mt-5">
            <div class="w-full">
              <div class="hidden w-full lg:block">
                <h3 class="text-2xl font-bold ">Clinic tour videos</h3>
                <p>
                  Please upload video of your stock (fridge and cupboard stock)
                </p>
                <div v-if="errors.includes('maxVidSize')"
                  class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
                  <span class="pl-0 span-text-validation">Upload size must be 200mb or less.</span>
                </div>
              </div>
              <div class="flex justify-between w-full lg:hidden">
                <div>
                  <h3 class="text-base font-semibold ">Clinic tour videos</h3>
                  <p>
                    Please upload video of your stock (fridge and cupboard
                    stock)
                  </p>
                </div>
                <div class="flex items-center">
                  <div class="vs-con-loading__container" style="text-align: center; position: relative">
                    <label
                        class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                        style="
                          border: 1px solid #cccccc;
                          display: inline-block;
                          width: 100%;
                          border-radius: 5px;
                          padding: 10px;
                          border: 1px solid rgba(var(--vs-primary),1);
                          color: rgba(var(--vs-primary),1);
                        "
                      >
                      <span>
                        <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                        <span class="vs-button-text vs-button--text"> Upload </span>

                        <input type="file" @change="uploadImage($event, 'videoTour')" id="fridge-file-input"
                            accept="video/mp4,video/x-m4v,video/*" hidden multiple />
                          <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap float-right lg:hidden justify-items-end" v-if="errors.includes('maxVidSize')">
                <div class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
                  style="display: inline-block">
                  <span class="span-text-validation">Upload size must be 200mb or less.</span>
                </div>
              </div>
              <div class="flex flex-wrap w-full px-5">
                <div :key="index" v-for="(image, index) in videos.videoTour" class="px-5">
                  <video class="hidden lg:block" width="300" height="250" controls preload="auto">
                    <source :src="image.videoLink" />
                  </video>
                  <video width="100%" height="250" class="lg:hidden" controls preload="auto">
                    <source :src="image.videoLink" />
                  </video>
                  <p>{{ formatDate(image.createdAt) }}</p>
                </div>
              </div>
            </div>
            <div class="items-center hidden lg:flex">
              <div class="vs-con-loading__container" style="text-align: center; position: relative">
                <label
                    class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                      border: 1px solid rgba(var(--vs-primary),1);
                      color: rgba(var(--vs-primary),1);
                    "
                  >
                  <span>
                    <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                    <span class="vs-button-text vs-button--text"> Upload </span>

                    <input type="file" @change="uploadImage($event, 'videoTour')" id="fridge-file-input"
                accept="video/mp4,video/x-m4v,video/*" hidden multiple />
                      <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div>
            <div>
              <vs-list>
                <vs-list-item :key="index" v-for="(note, index) in requestChanges.videoTour" icon="radio_button_checked"
                  :subtitle="`${note.requestNote} -   ${formatDate(
                    note.createdAt
                  )}`">
                </vs-list-item>
              </vs-list>
            </div>
          </div>
        </div>
        <div v-if="toggleScreenSize" class="flex items-center justify-end w-full pt-5">
          <vs-button size="lg" color="primary" @click="saveImages">Save</vs-button>
        </div>
      </div>
    </vs-card>
    <div :class="`popUpCustom ${!isMobileView ? `block` : `hidden`}`">
      <vs-popup title="Edit Record" :active.sync="popupActive" v-if="!isMobileView && isMobileView !== null">
        <editStocktakeForm :selectedStockTake="selectedStockTake" @onClose="handleCancel"
          @fetchStocktake="getClinicDrugbookProducts" :popupActive="popupActive" />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
import heic2any from "heic2any";
import complainceDrugbookTable from "@/views/components/org-owner/compliance/drugbook.vue";
import editStocktakeForm from "@/views/components/org-owner/compliance/editStocktakeForm.vue";
import ProgressBar from '@/views/components/drugbook/ProgressBar.vue';


export default {
  components: {
    complainceDrugbookTable,
    editStocktakeForm,
    ProgressBar
  },
  inject: ['getRoleRouteName'],
  data: () => ({
    isDigitalDrubook: false,
    isStocktake: false,
    popupActive: false,
    completeComplianceCheck: true,
    popupImageActivo: false,
    totalDocs: 0,
    imagePreviewSrc: "",
    status: "In Review",
    clinicId: "",
    complianceQuarter: "",
    complianceYear: "",
    complianceDetails: {},
    complianceDrugbook: [],
    selectedStockTake: {},
    orgId: null,
    errors: [],
    images: {
      drug: [],
      fridge: [],
      stock: [],
      emergency: [],
    },
    generalNotes: [],
    requestChanges: {
      drug: [],
      fridge: [],
      stock: [],
      emergency: [],
      videoTour: [],
    },
    videos: {
      videoTour: [],
    },
    statusList: [
      { text: "Up next", value: "upNext" },
      { text: "Ready for review", value: "inReview" },
      { text: "Completed", value: "completed" },
      { text: "Skipped", value: "skipped" },
      { text: "Missed", value: "failed" },
      { text: "Change Requested", value: "changeRequested" },
    ],
    // status:"Done",
    confirmActive: true,
    isMobileView: null,
    onEdit: true,
    showDetails: true,
  }),
  methods: {
    ...mapActions("monthlyCompliance", [
      "udpatebookingDetails",
      "uploadFiles",
      "downloadPdf",
      "updateVideos",
      "updatePhotos",
      "fetchDetails",
      "fetchPreviousMonthDetails",
      "fetchClinicDrugbookProducts",
      "fetchStocktakeFlagFeature"
    ]),
    ...mapActions("drugbook", [
      "fetchDrugEntries",
      "fetchDrugEntriesPDF",
    ]),
    async getStocktakeFlagFeature() {
      try {
        const res = await this.fetchStocktakeFlagFeature({
          orgId: this.orgId,
          clinicId: this.complianceDetails.clinicId,
        })
        this.isStocktake = res.data.data.status
      } catch (e) {
        return;
      }
    },
    async getClinicDrugbookProducts() {
      try {
        const res = await this.fetchClinicDrugbookProducts({ clinicId: this.complianceDetails.clinicId, complianceId: this.clinicId })

        this.complianceDrugbook = res.data.data;
      }
      catch (e) {
        return;
      }
    },
    openConfirm() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure you want to cancel the booking schedule?",
        accept: this.cancelBooking,
      });
    },
    rescheduleBooking() {
      this.$router.push({
        name: this.getRoleRouteName("ComplianceRescheduleBooking"),
        params: { clinicId: this.clinicId },
      });
    },
    async updateDetails(payload) {
      const res = await this.udpatebookingDetails({
        clinicId: this.clinicId,
        payload: payload,
      });
      if (res.data) {
        this.complianceDetails.bookingDate = null;
        this.$vs.notify({
          title: "Success",
          text: "Video call booking has been cancelled.",
          color: "success",
        });
        this.$vs.loading.close();
      }
    },
    async cancelBooking() {
      this.$vs.loading();
      var uuid = this.complianceDetails.bookingLink.split("/")[4];
      const res = await axios.post(
        `https://api.calendly.com/scheduled_events/${uuid}/cancellation`,
        {},
        {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_CALENDLY_TOKEN}`,
          },
        }
      );
      if (res.data.resource) {
        await this.updateDetails({
          bookingLink: null,
          bookingDate: null,
        });
      }
    },
    getStatus(status) {
      return _.find(this.statusList, ["value", status]).text;
    },
    gotoBookingLink() {
      window.open(this.complianceDetails.bookingLink);
    },
    redirectToComplianceList() {
      this.popupActivo = false;
      this.$router.push({ name: this.getRoleRouteName("Compliance") }).catch(() => { });
    },
    ImagePreview(src, imagefor = "") {
      this.popupImageActivo = true;
      this.imagePreviewSrc = { src: src, imagefor: imagefor };
    },
    async genratePdfReport(){
      this.$vs.loading()
      try {
        const res = await this.fetchDrugEntriesPDF({
          page: 1,
          limit: Number.MAX_SAFE_INTEGER,
          selectedClinic: this.complianceDetails.clinicId,
          isCurrentDateMinus30Days: true,
        });
        res.data.data.photoLink = res.data.data.Location;
        res.data.data.group = 'drugbook'
        this.images['drug'] = [
          res.data.data,
        ];
        this.$vs.notify({
            title: "Success",
            text: "Exported PDF from drugbook Updated.",
            color: "success",
          });

      } catch (error) {
        this.$vs.notify({
            title: "Failed",
            text:   "Export from drugbook. Try again!",
            color: "danger",
          });
      }
      this.$vs.loading.close()
    },
    async DownloadPdf({ url, name }) {
      const res = await this.downloadPdf(url);
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = name.includes('Quarterly Compliance') ? `${name} - Q${moment(this.complianceDetails.createdAt).utc().quarter()}` : `${name}-quarterly-compliance.pdf`;
      link.click()
      URL.revokeObjectURL(link.href)
    },
    async getDetails(clinicId) {
      const quarters = ['January - March', 'April - June', 'July - September', 'October - December']
      const res = await this.fetchDetails({ clinicId: clinicId });

      if (res.data.data.length > 0) {
        this.complianceDetails = res.data.data[0];
        if (res.data.data[0].bookingDate) {
          this.complianceDetails.bookingDate = `
          ${moment(res.data.data[0].bookingDate).format("MMMM DD YYYY")} -
          ${moment(res.data.data[0].bookingDate).format("hh:mm")} -
          ${moment(res.data.data[0].bookingDate)
              .add(30, "minutes")
              .format("hh:mm a")}
          `;
        }
        const complianceCreatedDate = moment(res.data.data[0].createdAt).utc()
        this.complianceQuarter = quarters[complianceCreatedDate.quarter() - 1]
        this.complianceYear = complianceCreatedDate.format(
          "YYYY"
        );
        this.images.drug = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "drugbook",
        ]).map((item) => item);
        this.images.fridge = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "fridgetemperature",
        ]).map((item) => item);
        this.images.stock = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "stockPhotos",
        ]).map((item) => item);
        this.images.emergency = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "emergencyPhotos",
        ]).map((item) => item);
        this.videos.videoTour = _.filter(res.data.data[0].uploadedVideos, [
          "group",
          "videoTour",
        ]).map((item) => item);

        this.requestChanges.drug = _.filter(res.data.data[0].changeRequests, [
          "group",
          "drug",
        ]).map((item) => item);
        this.requestChanges.fridge = _.filter(res.data.data[0].changeRequests, [
          "group",
          "fridge",
        ]).map((item) => item);
        this.requestChanges.stock = _.filter(res.data.data[0].changeRequests, [
          "group",
          "stock",
        ]).map((item) => item);
        this.requestChanges.emergency = _.filter(res.data.data[0].changeRequests, [
          "group",
          "emergency",
        ]).map((item) => item);
        this.requestChanges.videoTour = _.filter(
          res.data.data[0].changeRequests,
          ["group", "videoTour"]
        ).map((item) => item);

      }

    },
    formatDate(value, full = true) {
      const date = moment(value);
      if (full) {
        return date.format("MMMM DD YYYY");
      }
      return date.format("MMMM YYYY");
    },
    removePreview() {
      const index = _.findIndex(this.images[this.imagePreviewSrc.imagefor], [
        "photoLink",
        this.imagePreviewSrc.src,
      ]);
      this.images[this.imagePreviewSrc.imagefor].splice(index, 1);
      if (this.images[this.imagePreviewSrc.imagefor].length === 0) {
        this.errors.push(this.imagePreviewSrc.imagefor);
      }
      this.imagePreviewSrc = "";
      this.popupImageActivo = false;
    },
    async saveImages() {
      try {
        if (
          !this.images.drug.length ||
          !this.images.fridge.length ||
          !this.images.stock.length
        ) {
          this.errors = _.pullAll(
            [
              !this.images.drug.length ? "drug" : undefined,
              !this.images.fridge.length ? "fridge" : undefined,
              !this.images.stock.length ? "stock" : undefined,
              !this.images.emergency.length ? "emergency" : undefined,
            ],
            [undefined]
          );
          this.$vs.notify({
            title: "Failed",
            text: "Upload image for all category. Try again!",
            color: "danger",
          });
          return
        }

        this.errors = [];
        this.$vs.loading();
        if (
          this.images.drug.length > 0 ||
          this.images.fridge.length > 0 ||
          this.images.stock.length > 0 ||
          this.images.emergency.length > 0
        ) {
          const drugbook = this.images.drug;
          const fridgetemperature = this.images.fridge;
          const stockPhotos = this.images.stock;
          const emergencyPhotos = this.images.emergency;

          const data = {
            payload: {
              uploadedPhotos: [
                ...drugbook,
                ...fridgetemperature,
                ...stockPhotos,
                ...emergencyPhotos,
              ],
            },
            clinicId: this.clinicId,
          };
          await this.updatePhotos(data);
        }
        if (this.videos.videoTour.length > 0) {
          const videoTour = this.videos.videoTour;
          const videoData = {
            payload: {
              uploadedVideos: [...videoTour],
            },
            clinicId: this.clinicId,
          };
          await this.updateVideos(videoData);
        }
        setTimeout(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Success",
            text: "Monthly Compliance successfully saved.",
            color: "success",
          });
        }, 1000);
      } catch (error) {
        console.error(error);
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: "Failed to update Monthly Compliance. Try again!",
          color: "danger",
        });
      }
    },
    async uploadImage(event, imagefor = "") {
      try {
        let files = new FormData();
        const fileArray = Object.keys(event.target.files).map((item) => item);
        for (var i = 0; i < fileArray.length; i++){
          if(event.target.files[[fileArray[i]]]["type"].startsWith("image/heic")){
            const blob = new Blob([event.target.files[fileArray[i]]], { type: "image/heic" })
            const result = await heic2any({
              blob,
              toType: "image/jpeg",
              quality: 1,
            })
            var newfile = new File([result], `files[${fileArray[i]}].jpeg`);
            files.append(`files[${fileArray[i]}]`, newfile);
          }
          else{
            files.append(`files[${fileArray[i]}]`, event.target.files[fileArray[i]]);
          }
        }
        console.log(files, "files")
        if (
          (imagefor !== "videoTour" &&
            event.target.files[0]["type"].startsWith("image")) ||
          event.target.files[0]["type"].indexOf("pdf") > -1 ||
          (imagefor === "videoTour" &&
            event.target.files[0]["type"].startsWith("video"))
        ) {
          if (event.target.files[0]["size"] < 209715200) {
            this.$vs.loading({
              text: "Please wait while we're uploading your file(s)...",
            });
            this.errors = _.pullAll(
              [event.target.files[0]["size"] < 209715200 && undefined],
              [undefined]
            );
            const res = await this.uploadFiles(files);
            if (imagefor === "videoTour") {
              this.videos[imagefor] = [
                ...this.videos[imagefor],
                ...res.data.map((item) => {
                  item.videoLink = item.Location;
                  item.group = "videoTour";
                  return item;
                }),
              ];
            } else {
              this.images[imagefor] = [
                ...this.images[imagefor],
                ...res.data.map((item) => {
                  item.photoLink = item.Location;
                  item.group =
                    imagefor === "drug"
                      ? "drugbook"
                      : imagefor === "fridge"
                        ? "fridgetemperature"
                        : imagefor === "stock"
                          ? "stockPhotos"
                          : imagefor === "emergency"
                          ? "emergencyPhotos"
                          : "";
                  return item;
                }),
              ];
            }
            this.$vs.loading.close();
          } else {
            this.errors = _.pullAll(
              [
                event.target.files[0]["size"] > 209715200
                  ? "maxVidSize"
                  : undefined,
              ],
              [undefined]
            );
            this.$vs.notify({
              title: "Upload Failed",
              text: "File exceeds maximum file size",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Upload Failed",
            text: "File Format Not Supported",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      } catch (err) {
        this.$vs.loading.close();
        if (err && err.status === 415) {
          this.$vs.notify({
            title: "Upload Failed",
            text: "File Format Not Supported",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        } else {
          this.$vs.notify({
            title: "Upload Failed",
            text: "Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      }
    },
    getStatusData(data) {
      const status = {
        upNext: 'Up Next',
        failed: 'Missed',
        skipped: 'Skipped',
        inReview: 'Ready for Review',
        changeRequested: 'Change Requested',
        completed: 'Completed',
      }

      return status[data];
    },
    toggleModal(data) {
      this.selectedStockTake = { ...data };
      this.popupActive = true;
    },
    handleCancel() {
      this.popupActive = false;
      this.selectedStockTake = {};
    },
    handleOnEdit(data) {
      this.onEdit = data;
    },
    myEventHandler() {
      this.isMobileView = window.innerWidth < 768;
    }
  },
  computed: {
    toggleScreenSize: function () {
      if (this.isMobileView && this.isMobileView !== null) {
        return !this.popupActive;
      }
      return true;
    },
     monthCompliance: function () {
      return moment(this.complianceDetails.createdAt).subtract(1,'month').format('MM')
    },
    yearCompliance: function (){
      return moment(this.complianceDetails.createdAt).subtract(1,'month').format('YYYY')
    }
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.myEventHandler();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  async created() {
    this.clinicId = this.$route.params.clinicId;
    this.orgId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    )
    await this.getDetails(this.clinicId);
    const res = await this.fetchDrugEntries({
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
      selectedClinic: this.complianceDetails.clinicId,
    });
    this.isDigitalDrubook = res.data.data.docs.length > 0;
    // this.getStocktakeFlagFeature();
    this.getClinicDrugbookProducts()
    this.genratePdfReport()
  },
};
</script>

<style>
.hover-scale:hover {
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

.vs-image--img {
  background-position: 50%;
  padding-bottom: 100%;
  background-size: cover;
  display: block;
  position: relative;
}

.upnext,
.inreview {
  background-color: #E5F8EE;
  color: #84CDB8;
}

.skipped {
  background-color: #E2E8E6;
  color: #074230;
}

.failed {
  background-color: #FBE4E4;
  color: #EA5455;
}

.changerequested {
  background-color: #FF9F43;
  color: #FFF2E3;
}

.completed {
  background-color: #E2E8E6;
  color: #074230;
}
</style>
